import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useEnvironment } from "src/context/EnvironmentContext";
import i18n from "src/i18n";
import { Fullscreenable, Loader, NavLink, Select, ToggleGroup } from "src/components/atoms";
import { ErrorPlaceholder } from "src/components/molecules";
import { getClient } from "src/api/Client";
import { CrossChainBy } from "src/api/guardian-network/types";
import { Chart } from "./Chart";
import { Chain } from "@wormhole-foundation/sdk";
import { GlobeIcon, FullscreenIcon } from "src/icons/generic";
import { useWindowSize } from "src/utils/hooks";
import analytics from "src/analytics";
import { handleDownloadAs, transformData } from "./utils";
import "./styles.scss";

const MAINNET_TYPE_LIST = [
  { label: i18n.t("home.crossChain.volume"), value: "notional", ariaLabel: "Volume" },
  { label: i18n.t("home.crossChain.count"), value: "tx", ariaLabel: "Transactions" },
];

const TESTNET_TYPE_LIST = [
  { label: i18n.t("home.crossChain.count"), value: "tx", ariaLabel: "Transactions" },
];

const RANGE_LIST = [
  { label: "Last 7 days", value: "7d" },
  { label: "Last 30 days", value: "30d" },
  { label: "Last 90 days", value: "90d" },
  { label: "Last 365 days", value: "1y" },
  { label: "All Time", value: "all-time" },
];

const DOWNLOADABLE_LIST = [
  { label: "Download CSV", value: "csv" },
  { label: "Download PNG", value: "png" },
];

const CrossChainChart = ({ isHomePage = false }: { isHomePage?: boolean }) => {
  const { environment } = useEnvironment();
  const currentNetwork = environment.network;
  const { t } = useTranslation();

  const [TYPE_LIST, setTypeList] = useState(MAINNET_TYPE_LIST);
  const [selectedType, setSelectedType] = useState<CrossChainBy>("notional");
  const [selectedTimeRange, setSelectedTimeRange] = useState(RANGE_LIST[0]);

  const sourceRef = useRef<Chain | "">("");
  const targetRef = useRef<Chain | "">("");
  const fullscreenBtnRef = useRef(null);

  const { width } = useWindowSize();
  const isDesktop = width >= 1024;

  useEffect(() => {
    if (currentNetwork === "Mainnet") {
      setTypeList(MAINNET_TYPE_LIST);
    } else {
      setSelectedType("tx");
      setTypeList(TESTNET_TYPE_LIST);
    }
  }, [currentNetwork]);

  const { data, isError, isLoading, isFetching } = useQuery(
    ["getLastTxs", selectedType, selectedTimeRange.value],
    () =>
      getClient().guardianNetwork.getCrossChainActivity({
        by: selectedType,
        timeSpan: selectedTimeRange.value,
      }),
    { cacheTime: 0 },
  );

  const DownloadSelect = ({ className }: { className?: string }) => (
    <Select
      ariaLabel="Select Download Format"
      className={className}
      items={DOWNLOADABLE_LIST}
      name="downloadAs"
      onValueChange={({ value }) =>
        handleDownloadAs({ data, downloadAs: value, isDesktop, selectedTimeRange })
      }
      placeholder="Download"
      value={{ label: "Download", value: "" }}
    />
  );

  return (
    <Fullscreenable className="cross-chain" buttonRef={fullscreenBtnRef} itemName="crossChainChart">
      <div className="cross-chain-top">
        <div className="cross-chain-top-title">
          <GlobeIcon width={24} />
          {t("home.crossChain.title")}
          <div className="cross-chain-top-fullscreen" ref={fullscreenBtnRef}>
            <FullscreenIcon width={20} />
          </div>
          {isHomePage && (
            <NavLink
              className="token-activity-title-link"
              to="/analytics/chains"
              onClick={() => {
                analytics.track("viewMore", {
                  network: currentNetwork,
                  selected: "Cross-Chain Activity",
                });
              }}
            >
              View More
            </NavLink>
          )}
        </div>

        <DownloadSelect className="cross-chain-top-download" />
      </div>

      <div className="cross-chain-options">
        <ToggleGroup
          ariaLabel="Select type"
          items={currentNetwork === "Mainnet" ? TYPE_LIST : [TYPE_LIST[0]]}
          onValueChange={value => {
            setSelectedType(value);

            analytics.track("metricSelected", {
              network: currentNetwork,
              selected: value,
              selectedType: "crossChainChart",
            });
          }}
          value={selectedType}
        />

        <div className="cross-chain-options-filters">
          <div className="cross-chain-options-filters-group">
            <Select
              ariaLabel="Select Time Range"
              className="cross-chain-options-filters-select"
              items={RANGE_LIST}
              name="timeRange"
              onValueChange={(value: any) => {
                setSelectedTimeRange(value);

                analytics.track("crossChainChartTimeRange", {
                  network: environment.network,
                  selected: value.label,
                });
              }}
              value={selectedTimeRange}
            />

            <DownloadSelect className="cross-chain-options-filters-group-download" />
          </div>
        </div>
      </div>

      <div className="cross-chain-chart">
        {isLoading || isFetching ? (
          <Loader />
        ) : isError ? (
          <ErrorPlaceholder errorType="sankey" />
        ) : (
          <Chart
            currentNetwork={currentNetwork}
            data={transformData(data)}
            selectedTimeRange={selectedTimeRange}
            selectedType={selectedType}
            sourceRef={sourceRef}
            targetRef={targetRef}
          />
        )}
      </div>
    </Fullscreenable>
  );
};

export default CrossChainChart;
