import { BlockchainIcon, Tooltip } from "src/components/atoms";
import { formatNumber } from "src/utils/number";
import { Chain, ChainId, chainToChainId, Network } from "@wormhole-foundation/sdk";
import { useRef, useState } from "react";
import { CrossChainBy } from "src/api/guardian-network/types";

type ChainItem = {
  chain: Chain | "Others";
  chainId: ChainId | null;
  height: number;
  otherItems?: { chain: Chain; volume: number; percentage: number }[];
  percentage: number;
  top: number;
  volume: number;
};

type Props = {
  currentNetwork: Network;
  isDesktop: boolean;
  isSource: boolean;
  items: ChainItem[];
  onClick: (chain: Chain) => void;
  selectedChain: Chain | "";
  selectedType: CrossChainBy;
  showPercentage: boolean;
};

export const ChainListColumn = ({
  currentNetwork,
  isDesktop,
  isSource,
  items,
  onClick,
  selectedChain,
  selectedType,
  showPercentage,
}: Props) => {
  const showTxs = selectedType === "tx";
  const [enableTooltip, setEnableTooltip] = useState(true);

  // Added to work in fullscreen mode
  const columnRef = useRef<HTMLDivElement>(null);

  return (
    <div className="cross-chain-chart-container-column" ref={columnRef}>
      {items.map(({ chain, chainId, volume, percentage, height, top, otherItems }) => {
        const isSelected = selectedChain === chain;
        const commonClasses = `cross-chain-chart-container-column-item ${
          isSource ? "left" : "right"
        } ${isSelected ? "selected" : ""} ${
          !isDesktop ? (showPercentage ? "showPercentageMobile" : "showMoneyMobile") : ""
        }`;

        if (otherItems?.length > 0) {
          return (
            <Tooltip
              key={chain}
              className="cross-chain-chart-container-column-item-tooltip"
              closeOnTriggerClick={false}
              containerRef={columnRef}
              tooltip={otherItems.map(({ chain, volume, percentage }) => (
                <button
                  key={chain}
                  className={`${commonClasses} cross-chain-chart-container-column-item-tooltip-element`}
                  onClick={() => {
                    onClick(chain);
                    setEnableTooltip(false);
                    setTimeout(() => {
                      setEnableTooltip(true);
                    }, 100);
                  }}
                >
                  <BlockchainIcon chainId={chainToChainId(chain)} network={currentNetwork} />
                  <span className="chain">{chain}</span>
                  <span className="separator">|</span>
                  <span className="volume">
                    {showTxs ? "" : "$"}
                    {formatNumber(volume, volume < 1 ? 2 : 0)}
                  </span>
                  <span className="percentage">{formatNumber(percentage, 2)}%</span>
                </button>
              ))}
              maxWidth={false}
              type="info"
              avoidCollisions={false}
              enableTooltip={enableTooltip}
            >
              <div className={`${commonClasses} other-item`} style={{ top, height }}>
                <div className="icons">
                  {otherItems.map(({ chain }) => (
                    <BlockchainIcon
                      key={chain}
                      chainId={chainToChainId(chain)}
                      network={currentNetwork}
                      background="var(--color-gray-950)"
                    />
                  ))}
                </div>
                <span className="chain">{chain}</span>
                <span className="separator">|</span>
                <span className="volume">
                  {showTxs ? "" : "$"}
                  {formatNumber(volume, volume < 1 ? 2 : 0)}
                </span>
                <span className="percentage">{formatNumber(percentage, 2)}%</span>
              </div>
            </Tooltip>
          );
        }

        return (
          <button
            key={chain}
            className={commonClasses}
            style={{ top, height }}
            onClick={() => onClick(chain as Chain)}
          >
            <BlockchainIcon chainId={chainId} network={currentNetwork} />
            <span className="chain">{chain}</span>
            <span className="separator">|</span>
            <span className="volume">
              {showTxs ? "" : "$"}
              {formatNumber(volume, volume < 1 ? 2 : 0)}
            </span>
            <span className="percentage">{formatNumber(percentage, 2)}%</span>
          </button>
        );
      })}
    </div>
  );
};
