import { useState } from "react";
import { Chain, Network } from "@wormhole-foundation/sdk";
import { useWindowSize } from "src/utils/hooks";
import { BREAKPOINTS } from "src/consts";
import analytics from "src/analytics";
import { MoneyIcon, PercentageIcon, SwapVerticalIcon } from "src/icons/generic";
import { CrossChainBy } from "src/api/guardian-network/types";
import { WormholeScanBrand } from "src/components/molecules";
import { ChainListColumn } from "./ChainListColumn";
import { LinkPath } from "./LinkPath";
import { computeLinkPositions, transformAndSort } from "./utils";

type Props = {
  currentNetwork: Network;
  data: {
    [chain: string | Chain]: {
      outFlow: { chain: Chain; volume: number }[];
      inFlow: { chain: Chain; volume: number }[];
    };
  };
  selectedTimeRange: { label: string; value: string };
  selectedType: CrossChainBy;
  sourceRef: React.MutableRefObject<Chain | "">;
  targetRef: React.MutableRefObject<Chain | "">;
};

export const Chart = ({
  currentNetwork,
  data,
  selectedTimeRange,
  selectedType,
  sourceRef,
  targetRef,
}: Props) => {
  const { width } = useWindowSize();
  const isBigDesktop = width >= BREAKPOINTS.bigDesktop;
  const isDesktop = width >= BREAKPOINTS.desktop;
  const [showPercentage, setShowPercentage] = useState(true);

  const chainsList = Object.keys(data) as Chain[];

  const [sourceSelectedChain, setSourceSelectedChain] = useState<Chain | "">(
    sourceRef.current || (targetRef.current ? "" : chainsList[0]),
  );
  const [targetSelectedChain, setTargetSelectedChain] = useState<Chain | "">(targetRef.current);

  const sourceChainList = sourceSelectedChain
    ? transformAndSort({
        flows: chainsList
          .map(chain => {
            const outFlow = data[chain].outFlow ?? [];
            const totalOutFlow = outFlow.reduce((sum, entry) => sum + entry.volume, 0);
            return { chain, volume: totalOutFlow };
          })
          .sort((a, b) => b.volume - a.volume),
        selectedChain: sourceSelectedChain,
        addExtraItem: true,
      })
    : transformAndSort({
        flows: data[targetSelectedChain].inFlow,
        selectedChain: targetSelectedChain,
      });

  const targetChainList = sourceSelectedChain
    ? transformAndSort({
        flows: data[sourceSelectedChain]?.outFlow,
        selectedChain: sourceSelectedChain,
      })
    : transformAndSort({
        flows: chainsList
          .map(chain => {
            const inFlow = data[chain].inFlow ?? [];
            const totalInFlow = inFlow.reduce((sum, entry) => sum + entry.volume, 0);
            return { chain, volume: totalInFlow };
          })
          .sort((a, b) => b.volume - a.volume),
        selectedChain: targetSelectedChain,
        addExtraItem: true,
      });

  const positions = sourceSelectedChain
    ? computeLinkPositions({
        isDesktop: isDesktop,
        flowData: data[sourceSelectedChain]?.outFlow || [],
        sourceList: sourceChainList,
        targetList: targetChainList,
        selectedChain: sourceSelectedChain,
        selectedList: sourceChainList,
        direction: "out",
      })
    : computeLinkPositions({
        isDesktop: isDesktop,
        flowData: data[targetSelectedChain]?.inFlow || [],
        sourceList: sourceChainList,
        targetList: targetChainList,
        selectedChain: targetSelectedChain,
        selectedList: targetChainList,
        direction: "in",
      });

  return (
    <>
      <WormholeScanBrand />

      <div className="cross-chain-chart-header">
        <span className={sourceSelectedChain ? "selected" : ""}>SOURCE</span>
        <span className={targetSelectedChain ? "selected" : ""}>TARGET</span>
      </div>

      <div
        className={`cross-chain-chart-container ${
          sourceSelectedChain ? "left-selected" : "right-selected"
        }`}
      >
        <ChainListColumn
          currentNetwork={currentNetwork}
          isDesktop={isDesktop}
          isSource={true}
          items={sourceChainList}
          selectedChain={sourceSelectedChain}
          selectedType={selectedType}
          showPercentage={showPercentage}
          onClick={chain => {
            analytics.track("crossChainChart", {
              chain: chain,
              network: currentNetwork,
              selectedType,
              selected: "source",
              selectedTimeRange,
            });
            sourceRef.current = chain;
            targetRef.current = "";
            setSourceSelectedChain(chain);
            setTargetSelectedChain("");
          }}
        />

        <LinkPath
          isBigDesktop={isBigDesktop}
          isDesktop={isDesktop}
          isSource={!!sourceSelectedChain}
          positions={positions}
        />

        <ChainListColumn
          currentNetwork={currentNetwork}
          isDesktop={isDesktop}
          isSource={false}
          items={targetChainList}
          selectedChain={targetSelectedChain}
          selectedType={selectedType}
          showPercentage={showPercentage}
          onClick={chain => {
            analytics.track("crossChainChart", {
              chain: chain,
              network: currentNetwork,
              selectedType,
              selected: "destinations",
              selectedTimeRange,
            });
            sourceRef.current = "";
            targetRef.current = chain;
            setSourceSelectedChain("");
            setTargetSelectedChain(chain);
          }}
        />
      </div>

      <div className="cross-chain-mobile">
        <button
          className={`cross-chain-mobile-btn ${showPercentage ? "active" : ""}`}
          onClick={() => setShowPercentage(true)}
        >
          <PercentageIcon />
        </button>

        <button
          className={`cross-chain-mobile-btn ${!showPercentage ? "active" : ""}`}
          onClick={() => setShowPercentage(false)}
        >
          {selectedType === "tx" ? <SwapVerticalIcon /> : <MoneyIcon />}
        </button>
      </div>
    </>
  );
};
