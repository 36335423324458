import { Chain, ChainId, chainIdToChain, chainToChainId } from "@wormhole-foundation/sdk";
import { CrossChainActivity } from "src/api/guardian-network/types";

interface ICsvRow {
  "Target Chain": number;
  "Target Percentage": number;
  "Target Volume": string;
  "Source Chain": number;
  "Source Percentage": number;
  "Source Volume": string;
}

type ChainFlow = {
  outFlow: { chain: Chain; volume: number }[];
  inFlow: { chain: Chain; volume: number }[];
};

interface IList {
  chain: Chain | "Others";
  chainId: number;
  volume: number;
  percentage: number;
  height: number;
  top: number;
}

export const MAX_HEIGHT = 650;
const MIN_ITEM_HEIGHT = 28;

const TABLE_HEADERS = [
  "Source Chain",
  "Source Volume",
  "Source Percentage",
  "Target Chain",
  "Target Volume",
  "Target Percentage",
];

export const handleDownloadAs = ({
  data,
  downloadAs,
  isDesktop,
  selectedTimeRange,
}: {
  data: CrossChainActivity;
  downloadAs: "csv" | "png";
  isDesktop: boolean;
  selectedTimeRange: { label: string } | undefined;
}) => {
  if (!data) return;

  const csvData: any = [];
  data.forEach(item => {
    item.destinations.sort((a, b) => b.percentage - a.percentage);
    item.destinations.forEach(dest => {
      csvData.push({
        "Source Chain": item.chain,
        "Source Volume": item.volume,
        "Source Percentage": item.percentage,
        "Target Chain": dest.chain,
        "Target Volume": dest.volume,
        "Target Percentage": dest.percentage,
      });
    });
  });

  csvData.sort((a: ICsvRow, b: ICsvRow) => b["Source Percentage"] - a["Source Percentage"]);

  if (downloadAs === "csv") {
    const rows = csvData.map(
      (row: ICsvRow) =>
        `${row["Source Chain"]},${row["Source Volume"]},${row["Source Percentage"]},${row["Target Chain"]},${row["Target Volume"]},${row["Target Percentage"]}`,
    );

    const csvContent = [TABLE_HEADERS.join(","), ...rows].join("\n");
    createDownloadLink(
      csvContent,
      "text/csv",
      `Cross-chain Transfers - ${selectedTimeRange.label}.csv`,
    );
  } else if (downloadAs === "png") {
    const rows = csvData.map((row: ICsvRow) => [
      row["Source Chain"],
      row["Source Volume"],
      row["Source Percentage"],
      row["Target Chain"],
      row["Target Volume"],
      row["Target Percentage"],
    ]);

    const tableData = [TABLE_HEADERS, ...rows];

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const cellPadding = isDesktop ? 8 : 6;
    const cellHeight = isDesktop ? 32 : 20;
    const fontSize = isDesktop ? 16 : 12;
    const font = `${fontSize}px Roboto, sans-serif`;
    context.font = font;

    const columnWidths = TABLE_HEADERS.map((header, i) => {
      return (
        Math.max(
          context.measureText(header).width,
          ...rows.map((row: Array<string | number>) => context.measureText(`${row[i]}`).width),
        ) +
        cellPadding * 2
      );
    });

    const tableWidth = columnWidths.reduce((a, b) => a + b, 0);
    const tableHeight = tableData.length * cellHeight;

    canvas.width = tableWidth;
    canvas.height = tableHeight;

    context.fillStyle = "white";
    context.fillRect(0, 0, tableWidth, tableHeight);
    context.strokeStyle = "black";
    context.lineWidth = 1;
    context.font = font;

    tableData.forEach((row, rowIndex) => {
      row.forEach((cell: number | string, colIndex: number) => {
        const x = columnWidths.slice(0, colIndex).reduce((a, b) => a + b, 0);
        const y = rowIndex * cellHeight;

        context.strokeRect(x, y, columnWidths[colIndex], cellHeight);

        context.fillStyle = "black";
        context.fillText(`${cell}`, x + cellPadding, y + cellHeight / 2 + fontSize / 2 - 2);
      });
    });

    canvas.toBlob(blob => {
      try {
        createDownloadLink(
          blob,
          "image/png",
          `Cross-chain Transfers - ${selectedTimeRange.label}.png`,
        );
      } catch (error) {
        console.log("Error creating blob", error);
      }
    }, "image/png");
  }
};

export const createDownloadLink = (content: BlobPart, type: string, filename: string) => {
  const blob = new Blob([content], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
};

export const transformData = (
  data: {
    chain: ChainId;
    volume: string;
    destinations: {
      chain: ChainId;
      volume: string;
    }[];
  }[],
) => {
  const chainMap: Record<string, ChainFlow> = {};

  data.forEach(({ chain, volume, destinations }) => {
    if (!+volume) return;
    const chainKey = chainIdToChain(chain);

    if (!chainMap[chainKey]) {
      chainMap[chainKey] = { outFlow: [], inFlow: [] };
    }

    destinations.forEach(({ chain: destChain, volume: destVolume }) => {
      if (!+destVolume) return;

      const destKey = chainIdToChain(destChain);

      chainMap[chainKey].outFlow.push({
        chain: destKey,
        volume: parseFloat(destVolume),
      });

      if (!chainMap[destKey]) {
        chainMap[destKey] = { outFlow: [], inFlow: [] };
      }

      chainMap[destKey].inFlow.push({
        chain: chainKey,
        volume: parseFloat(destVolume),
      });
    });
  });

  Object.keys(chainMap).forEach(chain => {
    chainMap[chain].outFlow.sort((a, b) => b.volume - a.volume);
    chainMap[chain].inFlow.sort((a, b) => b.volume - a.volume);
  });

  const sortedChainEntries = Object.entries(chainMap).sort(([, a], [, b]) => {
    const totalVolumeA = a.outFlow.reduce((sum, flow) => sum + flow.volume, 0);
    const totalVolumeB = b.outFlow.reduce((sum, flow) => sum + flow.volume, 0);
    return totalVolumeB - totalVolumeA;
  });

  return Object.fromEntries(sortedChainEntries);
};

export const transformAndSort = ({
  flows,
  selectedChain,
  addExtraItem = false,
}: {
  flows: { chain: Chain | "Others"; volume: number }[];
  selectedChain: Chain | "";
  addExtraItem?: boolean;
}) => {
  if (!flows) return [];

  const initialIndex = 0;
  const endIndex = 9;

  const sorted = [...flows].sort((a, b) => b.volume - a.volume);

  const visibleItems = sorted.slice(initialIndex, endIndex);
  const otherItemsRaw = sorted.slice(endIndex).filter(item => item.volume > 0);

  const totalVolumeAll = sorted.reduce((acc, curr) => acc + curr.volume, 0);
  const calcPercentage = (volume: number) => (volume / totalVolumeAll) * 100;

  const otherItems = otherItemsRaw.map(item => ({
    ...item,
    percentage: calcPercentage(item.volume),
  })) as {
    chain: Chain;
    percentage: number;
    volume: number;
  }[];

  // If selectedChain is in otherItems, remove it from otherItems and add it to visibleItems
  if (addExtraItem && otherItems.some(item => item.chain === selectedChain)) {
    const selectedItem = otherItems.find(item => item.chain === selectedChain)!;
    visibleItems.push({
      chain: selectedItem.chain,
      volume: selectedItem.volume,
    });
    const selectedIndex = otherItems.indexOf(selectedItem);
    otherItems.splice(selectedIndex, 1);
  }

  const withOthers = [...visibleItems];

  // If exists only one item in "others", add it to the visible items
  if (otherItems.length === 1) {
    withOthers.push({
      chain: otherItems[0].chain,
      volume: otherItems[0].volume,
    });
    otherItems.length = 0;
  } else if (otherItems.length > 1) {
    const othersVolume = otherItems.reduce((acc, curr) => acc + curr.volume, 0);
    withOthers.push({
      chain: "Others",
      volume: othersVolume,
    });
  }

  const totalVolume = withOthers.reduce((acc, curr) => acc + curr.volume, 0);
  const count = withOthers.length;
  const fixedHeight = MIN_ITEM_HEIGHT * count;

  if (fixedHeight > MAX_HEIGHT) {
    const evenHeight = MAX_HEIGHT / count;
    let currentTop = 0;

    return withOthers.map(({ chain, volume }, i) => {
      const percentage = (volume / totalVolume) * 100;
      const height = evenHeight;
      const item = {
        chain,
        chainId: chain === "Others" ? null : chainToChainId(chain),
        volume,
        percentage,
        height,
        top: currentTop,
        otherItems: chain === "Others" ? otherItems : [],
      };
      currentTop += height;
      return item;
    });
  }

  const availableHeight = MAX_HEIGHT - fixedHeight;
  let currentTop = 0;

  return withOthers.map(({ chain, volume }) => {
    const volumeRatio = volume / totalVolume;
    const extraHeight = volumeRatio * availableHeight;
    const height = MIN_ITEM_HEIGHT + extraHeight;
    const percentage = volumeRatio * 100;

    const item = {
      chain,
      chainId: chain === "Others" ? null : chainToChainId(chain),
      volume,
      percentage,
      height,
      top: currentTop,
      otherItems: chain === "Others" ? otherItems : [],
    };

    currentTop += height;
    return item;
  });
};

export const computeLinkPositions = ({
  isDesktop,
  flowData,
  sourceList,
  targetList,
  selectedChain,
  selectedList,
  direction,
}: {
  isDesktop: boolean;
  flowData: {
    chain: Chain;
    volume: number;
  }[];
  sourceList: IList[];
  targetList: IList[];
  selectedChain: Chain | "";
  selectedList: IList[];
  direction: "in" | "out";
}) => {
  const gap = 2,
    minPathHeight = 1,
    maxItems = 10;

  if (flowData.length > maxItems) {
    flowData = flowData.slice(0, maxItems);
  }

  const fromList = direction === "out" ? selectedList : targetList;
  const toList = direction === "out" ? targetList : sourceList;

  const selected = fromList.find(item => item.chain === selectedChain);
  const thisTotalHeight = (selected?.height || 0) - (isDesktop ? 1 : 2);
  const thisTotalVolume = selected?.volume || 0;
  const thisTop = selected?.top || 0;

  const sortedFlow = [...flowData].sort((a, b) => b.volume - a.volume);

  const count = sortedFlow.length;
  const totalGaps = Math.max(0, (count - 1) * gap);
  const adjustedHeight = Math.max(0, thisTotalHeight - totalGaps);
  const minPathHeightSum = count * minPathHeight;
  const flexibleHeight = Math.max(0, adjustedHeight - minPathHeightSum);

  let fromTop = direction === "out" ? thisTop : 0;
  let toTop = direction === "out" ? 0 : thisTop;

  const findMatchingItem = (list: any[], chain: Chain) => {
    const exactMatch = list.find(entry => entry.chain === chain);
    if (exactMatch) return exactMatch;

    // Find if it is inside "Others"
    const othersGroup = list.find(
      entry =>
        entry.chain === "Others" &&
        entry.otherItems?.some((o: { chain: Chain }) => o.chain === chain),
    );
    return othersGroup || null;
  };

  return sortedFlow.map(item => {
    const toItem = findMatchingItem(toList, item.chain);
    const fixedHeight = toItem?.height - gap || 0;

    const volumeRatio = thisTotalVolume > 0 ? item.volume / thisTotalVolume : 0;
    const flexibleBlockHeight = minPathHeight + volumeRatio * flexibleHeight;

    let fromBlockHeight, toBlockHeight;

    if (direction === "out") {
      fromBlockHeight = flexibleBlockHeight;
      toBlockHeight = fixedHeight;
    } else {
      fromBlockHeight = fixedHeight;
      toBlockHeight = flexibleBlockHeight;
    }

    const TL = fromTop;
    const BL = TL + fromBlockHeight;

    const TR = toTop;
    const BR = TR + toBlockHeight;

    fromTop += fromBlockHeight + gap;
    toTop += toBlockHeight + gap;

    return {
      chain: item.chain,
      volume: item.volume,
      TL,
      BL,
      TR,
      BR,
      groupedInOthers: toItem?.chain === "Others" || false,
    };
  });
};
