const LockIcon = ({ width = 24 }: { width?: number }) => (
  <svg
    fill="none"
    height={width}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.75 10.75H17.25V18.25H6.75V10.75Z" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M15.25 10.75V8C15.25 6.20507 13.7949 4.75 12 4.75C10.2051 4.75 8.75 6.20507 8.75 8V10.75"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default LockIcon;
