import { useRef } from "react";
import { MAX_HEIGHT } from "./utils";

type Props = {
  isBigDesktop: boolean;
  isDesktop: boolean;
  isSource: boolean;
  positions: {
    chain: string;
    volume: number;
    TL: number;
    BL: number;
    TR: number;
    BR: number;
  }[];
};

export const LinkPath = ({ isDesktop, isBigDesktop, isSource, positions }: Props) => {
  const svgRef = useRef(null);
  const svgWidth = svgRef.current?.clientWidth || 560;

  const getGradientColor = (side: "start" | "end") => {
    if (!isDesktop) return "#4d4a67";
    if (isSource && side === "start")
      return isBigDesktop ? "var(--color-plum)" : "var(--color-plum-70)";
    if (!isSource && side === "end")
      return isBigDesktop ? "var(--color-plum)" : "var(--color-plum-70)";
    return "#121212";
  };

  return (
    <svg className="cross-chain-chart-container-svg" height={MAX_HEIGHT} ref={svgRef} width="100%">
      <defs>
        <linearGradient id="pathGradient" x1={0} x2={1} y1={0} y2={0}>
          <stop offset="0%" stopColor={getGradientColor("start")} />
          <stop offset="100%" stopColor={getGradientColor("end")} />
        </linearGradient>
      </defs>
      <g className="cross-chain-chart-container-svg-group">
        {positions.map(({ TL, BL, TR, BR }, i) => (
          <path
            key={`${TL}-${BL}-${TR}-${BR}`}
            className={`cross-chain-chart-container-svg-group-path ${
              isSource ? "source" : "target"
            }`}
            d={`M0,${TL}C${svgWidth / 2},${TL},${
              svgWidth / 2
            },${TR},${svgWidth},${TR}C${svgWidth},${TR},${svgWidth},${BR},${svgWidth},${BR}C${
              svgWidth / 2
            },${BR},${svgWidth / 2},${BL},0,${BL}Z`}
            fill="url(#pathGradient)"
            opacity={1 - i * 0.08}
            style={{ animationDuration: `${(i + 2) * 0.05}s` }}
          />
        ))}
      </g>
    </svg>
  );
};
